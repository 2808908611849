@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

*, *:before, *:after {
  box-sizing: border-box;
}

:root {
  --primary-color: #111a29;
  --secondary-color: #a4c9ff;
  --secondary-color-light: #d6e3ff;
  --primary-dark-color: rgb(39, 94, 167);
  --primary-color15: rgba(39, 94, 167, 0.15);
  --red-color: #f78083;
  --green-color: #13e00e;
  --green-color30: rgb(19 224 14 / 30%);
  --yellow-color: #faad14;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--primary-dark-color) inset !important;
  box-shadow: 0 0 0 30px var(--primary-dark-color) inset !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
input:autofill {
  box-shadow: 0 0 0 30px var(--primary-dark-color) inset !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

a,
a:visited {
  color: var(--secondary-color);
  text-decoration: none;
}
a:hover {
  color: var(--secondary-color-light);
  text-decoration: underline;
}

#layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

tr th.MuiTableCell-head {
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.clickable-row:hover {
  opacity:.8;
  background-color: var(--primary-color);
  cursor: pointer;
}
.thumbnail-cell img {
  object-fit: cover;
  width: 100%;
  height: 70px
}

.error-message-table-row {
  white-space: nowrap;
}

.strikethrough,
.strikethrough * {
  text-decoration: line-through;
}

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}