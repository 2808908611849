.event-cards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: visible;
  margin-top: 1rem;
  gap: 1rem;
}

.event-card {
  --md-icon-size: 14px;

  text-decoration: none;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--md-sys-color-on-background);
  -webkit-tap-highlight-color: rgba(255, 255, 255, .1);
  /* For md-ripple */
  position: relative;
  --container-padding-x: 0.5rem;
  padding: 0 var(--container-padding-x) 0.5rem;
  gap: 6px;
  width: 200px;
  height: 180px;
  overflow: hidden;
  animation: fade-in 1s ease-out forwards;
}

.event-card:hover img {
  transition: transform 0.2s;
  transform: scale(1.05);
  transform-origin: bottom center;
}

.event-card .event-image-wrapper {
  position: relative;
  z-index: 1;
  max-height: 55%;
  width: calc(100% + 2 * var(--container-padding-x));
  aspect-ratio: 1 / 1;
  margin-left: calc(-1 * var(--container-padding-x));
  margin-right: calc(-1 * var(--container-padding-x));
}
.event-card .event-image-wrapper .event-image-blurred {
  filter: blur(10px);
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}
.event-card .event-image-wrapper img {
  width: 100%;
  height: 100%;
}
.event-card .event-image-wrapper:not(.placeholder-image) .event-image {
  object-fit: cover;
}
.event-card .event-image-wrapper .event-image {
  transform-origin: bottom center;
  transition: transform 0.4s;
}

.event-card .event-card-title {
  margin: 0;
  width: 100%;
  text-align: start;
  padding: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}

.event-card md-icon {
  color: var(--md-sys-color-on-background);
}

.event-card .datetime-container,
.event-card .location-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;
  font-size: 0.85rem;
}
.event-card .datetime-container > * {
  text-decoration: none;
}

.event-card .clearfix {
  flex: 1 1 0%;
}

.event-card.horizontal {
  flex-direction: row;
  min-width: initial;
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
  padding-bottom: 0;
  max-height: 180px;
}
.event-card.horizontal .event-image-wrapper {
  width: 100%;
  max-height: inherit;
}
.event-card.horizontal .event-image-wrapper .event-image {
  transform-origin: right center;
}

.event-card.horizontal .event-card-title {
  margin-bottom: 4px;
}

.event-card.horizontal .datetime-container {
  flex-wrap: wrap;
  gap: 2px;
}
.event-card.horizontal .location-container {
  flex-wrap: nowrap;
  margin-bottom: 4px;
  gap: 2px;
}
.event-card .location-container span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event-card.horizontal .clearfix {
  flex: 1 1 100%;
}

@media screen and (min-width: 768px) {
  .event-card {
    --md-icon-size: 16px;
  }
  .event-card img {
    max-height: 200px;
  }
  .event-card.horizontal {
    width: 310px;
  }
}

@media screen and (min-width: 1024px) {
  .event-card.horizontal {
    width: 350px;
  }
}